<template>
  <b-container
    id="calendar-list"
    fluid
    class="calendar-list-page__body mb-6 mt-6"
  >
    <b-row>
      <b-col>
        <div class="card card-custom gutter-b">
          <div class="card-body mt-0">
            <b-row>
              <b-col cols="3">
                <b-row>
                  <b-col class="col-picker">
                    <v-date-picker
                      v-model="filterDate"
                      :header-date-format="formatDateHeader"
                      :first-day-of-week="0"
                      no-title
                      locale="vi-VN"
                      class="calendar-list-page__body__date-picker"
                      @change="onChangeDatePicker"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <div
                      style="
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 20px;
                      "
                    >
                      Bộ lọc
                    </div>

                    <basic-select
                      :value.sync="filterCoach"
                      :options="listCoaches"
                      multiple
                      label="Coach"
                      track-by="id"
                      value-label="fullName"
                      placeholder="---Chọn coach---"
                      @update:value="fetchCalender"
                    />

                    <b-form-group
                      label="Loại lịch hẹn"
                      v-slot="{ ariaDescribedby }"
                      class="calendar-list-page__body__schedule-filter"
                    >
                      <b-form-checkbox-group
                        id="checkbox-group-2"
                        v-model="filterTypes"
                        :aria-describedby="ariaDescribedby"
                        name="flavour-2"
                        @input="fetchCalender"
                      >
                        <b-form-checkbox
                          v-for="type in listTypes"
                          :key="type.value"
                          :value="type.value"
                          :style="`color: ${type.color};`"
                          class="calendar-check-item"
                          >{{ type.text }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="9">
                <b-row>
                  <b-col cols="9" class="col-header-calendar">
                    <b-button
                      class="mr-2 calendar-list-page__body__today-button"
                      @click="onClickTodayButton"
                      >Hôm nay
                    </b-button>

                    <v-btn
                      class="mr-2"
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="onClickPrevButton"
                    >
                      <v-icon> mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      class="mr-2"
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="onClickNextButton"
                    >
                      <v-icon> mdi-chevron-right</v-icon>
                    </v-btn>

                    <span v-if="$refs.calendar" class="header-calendar-title">{{
                      formatCalenderHeader(rangeCalender)
                    }}</span>
                  </b-col>

                  <b-col cols="3" class="d-flex justify-content-end">
                    <b-button
                      v-if="isWritePermission"
                      class="btn btn-success ml-2"
                      type="button"
                      @click="openScheduleFormModal"
                    >
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/plus.svg"
                        />
                      </span>
                      Tạo lịch hẹn
                    </b-button>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <v-calendar
                      ref="calendar"
                      v-model="focus"
                      :events="calenders"
                      :type="calendarType"
                      :interval-height="72"
                      :short-intervals="false"
                      :weekdays="weekday"
                      :weekday-format="formatWeekday"
                      locale="vi-VN"
                      event-overlap-mode="column"
                      class="calendar-list-page__body__calendar"
                      @click:day="onClickCalendarDay"
                      @click:event="onClickSchedule"
                      @change="onChangeCalender"
                    >
                      <template v-slot:day-label-header="props">
                        <div style="font-weight: bold; font-size: 22px">
                          {{ props.day }}
                        </div>
                      </template>
                      <template v-slot:event="props">
                        <div
                          class="h-100 w-100 p-2"
                          :style="getDayBodyStyle(props.event)"
                        >
                          <div
                            :style="{ color: getTextColor(props.event.type) }"
                          >
                            {{
                              `${props.eventParsed.start.time} - ${props.eventParsed.end.time}`
                            }}
                          </div>
                          <div
                            :style="{ color: getTextColor(props.event.type) }"
                            class="font-weight-boldest"
                          >
                            {{ props.event.name }}
                          </div>
                        </div>
                      </template>
                      <template v-slot:day-body="{ date }">
                        <div
                          v-if="cal && cal.start === date"
                          :style="{ top: nowY }"
                          class="v-current-time first"
                        ></div>
                      </template>
                    </v-calendar>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>

    <calendar-schedule-form
      :type-form="typeForm"
      @save="callbackSaveSchedule"
      @hide="onHideScheduleForm"
    />
    <calendar-schedule-detail
      :calendar-id="selectedSchedule.id"
      @edit="onEditSchedule"
      @cancel-schedule="onCancelSchdule"
    />
    <!-- <ask-copy-invitation-link
      :calendar-name="calendarName"
      :calendar-id="calendarId"
      :meeting-link="meetingLink"
      :dynamic-link="dynamicLink"
    /> -->
  </b-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  GET_CALENDER,
  CREATE_CALENDER,
  UPDATE_CALENDER,
  RESET_STATE,
  SET_LOADING,
} from '@/core/services/store/calender.module';
import { calenderMixin } from './mixins/CalenderList';
import { MODAL_TYPE } from '@/core/plugins/constants';
const { mapActions, mapState, mapMutations } = createNamespacedHelpers(
  'calender',
);

export default {
  name: 'CalendarList',

  mixins: [calenderMixin],

  components: {
    CalendarScheduleForm: () => import('./CalendarScheduleForm'),
    CalendarScheduleDetail: () => import('./CalendarScheduleDetail'),
    // AskCopyInvitationLink: () => import('./ModalAskCopy.vue'),
  },

  data() {
    return {
      // Some datas is in CalenderList.js
      focus: '',
      filterCoach: [],
      filterTypes: [],
      filterDate: '',

      selectedSchedule: {},
      meetingLink: null,
      calendarId: null,
      calendarName: null,
      dynamicLink: null,

      typeForm: MODAL_TYPE.CREATE,
      rangeCalender: {
        fromDate: '',
        toDate: '',
      },
    };
  },

  computed: {
    ...mapState(['calenders']),

    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },

  watch: {
    focus(val) {
      if (val) this.filterDate = val;
    },
  },

  mounted() {
    this.$refs.calendar.checkChange();
  },

  beforeDestroy() {
    this.RESET_STATE();
  },

  methods: {
    // Some functions is in CalenderList.js
    ...mapActions({
      GET_CALENDER,
      CREATE_CALENDER,
      UPDATE_CALENDER,
    }),
    ...mapMutations({ SET_LOADING, RESET_STATE }),

    fetchCalender() {
      const params = this.formatQueryParams({
        ...this.rangeCalender,
        filterCoach: this.filterCoach || [],
        filterTypes: this.filterTypes,
      });

      this.GET_CALENDER(params);
    },

    onChangeCalender({ start, end }) {
      this.rangeCalender.fromDate =
        new Date(`${start.date}T00:00:00`).getTime() / 1000;
      this.rangeCalender.toDate =
        new Date(`${end.date}T23:59:59`).getTime() / 1000;

      this.fetchCalender();
    },

    onEditSchedule() {
      this.typeForm = MODAL_TYPE.EDIT;
      this.$bvModal.hide('calendar-schedule-detail-modal');
      this.openScheduleFormModal();
    },

    onCancelSchdule() {
      this.fetchCalender();
    },

    onHideScheduleForm() {
      this.typeForm = MODAL_TYPE.CREATE;
    },

    openScheduleFormModal() {
      this.$bvModal.show('calendar-schedule-add-modal');
    },

    onClickPrevButton() {
      this.$refs.calendar.prev();
    },

    onClickNextButton() {
      this.$refs.calendar.next();
    },

    onClickCalendarDay(e) {
      this.focus = e.date;
    },

    onClickTodayButton() {
      this.focus = '';

      const currentDate = new Date().toISOString();
      this.filterDate = currentDate.split('T')[0];
    },

    onClickSchedule(e) {
      this.selectedSchedule = { ...e.event };
      this.openScheduleDetailModal();
    },

    openScheduleDetailModal() {
      this.$bvModal.show('calendar-schedule-detail-modal');
    },

    onChangeDatePicker(e) {
      this.focus = e;
    },

    callbackSaveSchedule: async function (event = {}) {
      const { typeForm, data } = event;

      try {
        this.SET_LOADING(true);
        if (typeForm === MODAL_TYPE.CREATE) {
          let response = await this.CREATE_CALENDER(data);
          if(response?.data.name === "NO_HOST_ZOOM") {
            this.toastCreateError();
          }
          else {
            if (response?.data.zoomTypeId == 1) {
            this.meetingLink = response?.data.meetingLink;
            this.calendarId = response?.data.id;
            this.calendarName = response?.data.name;
            /*** Gen Dynamic Link */
            // const longDynamicLink = `https://click.diab.com.vn/referralCode?link=https://diab.com.vn/calendar=${this.calendarId}&ofl=https://zoom.diab.com.vn?calendarId=${this.calendarId}&apn=com.vbhc.diab&ibi=com.cactusoftware.diab&isi=1569353448&sd=Ứng dụng hoàn toàn miễn phí giúp kiểm soát bệnh đái tháo đường và kết nối với chuyên gia&si=https://9s-booking.s3.ap-southeast-1.amazonaws.com/diab/linkzoom.jpg&st=${this.calendarName}`;
            // let dynamicLinks = await this.$api.getFirebaseDynamicLinks({ longDynamicLink: longDynamicLink });
            // this.dynamicLink = dynamicLinks;
            // this.$api.put(`Calendar/UpdateDynamicLInk/${this.calendarId}`, { DynamicLink: dynamicLinks }).then(() => {});
            // this.$bvModal.show('ask-copy-modal');
            } else {
              this.toastCreateSuccess();
            }
          }
        } else {
          await this.UPDATE_CALENDER(data);
          this.toastUpdateSuccess();
        }
        this.$bvModal.hide('calendar-schedule-add-modal');
      } catch (error) {
        console.error(error);
      } finally {
        this.SET_LOADING(false);
      }
    },
  },
};
</script>

<style lang="scss">
.v-date-picker-header {
  display: grid !important;
  grid-template-areas: 'title title previous next';
  padding: 4px 12px !important;

  &__next {
    grid-area: title;
  }

  .v-btn {
    &:first-child {
      grid-area: previous;
    }
    &:last-child {
      grid-area: next;
    }
  }
}

.col-picker {
  padding: 4px;

  .v-picker__body {
    margin-left: 0;

    .v-date-picker-header {
      padding: 1px !important;
    }
    .v-date-picker-table {
      padding: 0 !important;
    }
  }
}

.col-header-calendar {
  display: flex;
  align-items: center;
  padding-left: 71px;
}

.header-calendar-title {
  font-weight: 600;
  font-size: 14px;
}

.calendar-check-item {
  .custom-control-label {
    color: inherit;
    font-weight: 600;
  }
}

.calendar-list-page__body {
  &__date-picker {
    .v-btn--active,
    .v-date-picker-table__current {
      .v-btn__content {
        color: #01645a;
      }
    }
    .v-date-picker-table__current {
      border-color: #b1dddb;
      background-color: #b1dddb;
    }
    .v-btn--active {
      border-color: rgba($color: #b1dddb, $alpha: 0.5);
      background-color: rgba($color: #b1dddb, $alpha: 0.5);
    }
  }

  &__schedule-filter {
    div[role='group'] {
      display: flex;
      flex-direction: column;

      .custom-checkbox {
        margin-bottom: 8px;
      }
    }
  }

  &__today-button.btn-secondary {
    border-color: #008479;
    color: #008479;
    background-color: #ffffff;
  }

  &__calendar {
    border: none !important;

    .v-event-timed-container {
      margin: 0;

      .v-event-timed {
        border: none !important;
      }
    }

    .v-calendar-daily_head-day {
      border-top: #e0e0e0 1px solid;
      height: 72px;
    }
  }

  .v-picker__body {
    width: 100% !important;
    max-width: 290px;
  }
}

#calendar-list {
  .v-calendar-events .v-event-timed {
    border: none !important;
  }
}
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
